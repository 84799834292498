import { FC, useEffect, useState } from "react";
import { OperationEnum, ReportTypeEnum, StatusEnum } from "../../shared/models/Enums";
import { ResourcesService } from "../../shared/services/ResourcesService";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormWithHandle } from "../../shared/components/FormWithHandle/FormWithHandle";
import { selectUserLanguage } from "../login/loginSlice";
import Button from "react-bootstrap/esm/Button";
import { getReportAsync, getReportCompaniesAsync, getReportCompanyAsync, getReportSurveysAsync, reportCompaniesList, reportCompanyEdit, reportSelectedCompanyId, reportSelectedReportId, reportSelectedSurveyId, reportCompareCompaniesList, editCompanyChangeQuery, editCompanyLoadNextPage, editCompanyLoadPreviousPage, reportLeadershipLevelList, reportSelectedLeadershipLevel, reportShowLeadershipFilter, getReportLeadershipLevelAsync, reportAddedRecommendationsList, getRecommendationsAsync, reportCompanyCompare, getReportCompareCompanyAsync } from "./reportSlice";
import { Col, Form, Row } from "react-bootstrap";
import { VerticallyCenteredModal } from "../../shared/components/VerticallyCenteredModal/VerticallyCenteredModal";
import { GenericList } from "../../shared/components/GenericList/GenericList";
import { HelperService } from "../../shared/services/HelperService";
import { DeleteButton } from "../../shared/components/DeleteButton/DeleteButton";
import { useNavigate } from "react-router-dom";
import { EditCompanyData } from "../company/models/EditCompanyData";
import { RecommendationData } from "../../shared/models/Entities";

type ReportProps = {
    //     status: StatusEnum,
    //     operation : OperationEnum,
};

export const Report: FC<ReportProps> = () => {
    const companyList = useAppSelector(reportCompaniesList);
    const leadershipLevelList = useAppSelector(reportLeadershipLevelList);
    const editCompany = useAppSelector(reportCompanyEdit);
    const compareCompany = useAppSelector(reportCompanyCompare);
    const compareCompaniesList = useAppSelector(reportCompareCompaniesList);
    const addedRecommendationsList = useAppSelector(reportAddedRecommendationsList);

    const selectedCompanyId = useAppSelector(reportSelectedCompanyId);
    const selectedSurveyId = useAppSelector(reportSelectedSurveyId);
    const selectedReportId = useAppSelector(reportSelectedReportId);
    const selectedLeadershipLevelId = useAppSelector(reportSelectedLeadershipLevel);
    const showLeadershipFilter = useAppSelector(reportShowLeadershipFilter);

    const [companyToCompareId, setCompanyToCompareId] = useState(undefined as string | undefined);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getReportCompaniesAsync({
            skip: 0,
            take: 2000,
            query: ""
        }));
        dispatch(getReportSurveysAsync({
            skip: 0,
            take: 2000,
            query: ""
        }));
    }, [dispatch]);

    useEffect(() => {
        // When selectedCompanyId changes, dispatch getCompanyAsync
        if (selectedCompanyId) {
            setCompanyId(selectedCompanyId);
            dispatch(getReportCompanyAsync(selectedCompanyId));
        }
    }, [dispatch, selectedCompanyId]);

    useEffect(() => {
        // When compareCompanyId changes, dispatch getCompanyAsync
        if (companyToCompareId) {
            setCompanyToCompareId(companyToCompareId);
            dispatch(getReportCompareCompanyAsync(companyToCompareId));
        }
    }, [dispatch, companyToCompareId]);

    useEffect(() => {
        if (selectedSurveyId) {
            setSurveyId(selectedSurveyId);
        }
    }, [dispatch, selectedSurveyId]);

    useEffect(() => {
        if (selectedLeadershipLevelId) {
            setLeadershipLevelId(selectedLeadershipLevelId);
        }
    }, [dispatch, selectedLeadershipLevelId]);

    const [divisionToCompareId, setDivisionToCompareId] = useState(undefined as string | undefined);

    useEffect(() => {
        if (compareCompany && compareCompany.surveys.length > 0) {
            setDivisionToCompareId(compareCompany.surveys[0].id);
        }
    }, [dispatch, compareCompany]);

    const status = StatusEnum.Idle;

    const lang = useAppSelector(selectUserLanguage);

    const [companyId, setCompanyId] = useState("");
    const [reportType, setReportType] = useState(1);
    const [surveyId, setSurveyId] = useState("");
    const [leadershipLevelId, setLeadershipLevelId] = useState(undefined as string | undefined);
    const [compareCompanies, setCompareCompanies] = useState(compareCompaniesList.data);
    const [showLeadership, setShowLeadership] = useState(false);
    const [showRecommendations, setShowRecommendations] = useState(false);
    const [addedRecommendations, setAddedRecommendations] = useState(addedRecommendationsList.data);

    const [surveysModalShow, setSurveysModalShow] = useState(false);
    const [recommendationsModalShow, setRecommendationsModalShow] = useState(false);

    const tableHeaders = ['#', ResourcesService.translate('Title', lang)];
    const addCompareCompany = (survey: EditCompanyData) => HelperService.add(survey, compareCompanies, setCompareCompanies);
    const removeCompareCompany = (survey: EditCompanyData) => HelperService.remove(survey, compareCompanies, setCompareCompanies);
    const addRecommendation = (recommendation: RecommendationData) => HelperService.add(recommendation, addedRecommendations, setAddedRecommendations);
    const removeRecommendation = (recommendation: RecommendationData) => HelperService.remove(recommendation, addedRecommendations, setAddedRecommendations);

    const navigate = useNavigate();
    const routeChange = (path: string) => navigate(path);

    function getPDF() {
        var sids = compareCompanies.map(x => x.id);
        var rids = addedRecommendations.map(x => x.id);

        dispatch(getReportAsync({
            reportType,
            companyId,
            surveyId,
            leadershipLevelId,
            compareCompanies: sids,
            selectedRecomendations: rids,
            companyToCompareId,
            divisionToCompareId
        }));
    }

    function onReportSelected(e: React.ChangeEvent<HTMLSelectElement>) {
        let reportType = Number(e.currentTarget.value);
        setReportType(reportType)

        if (reportType === ReportTypeEnum.OneTwoCompanyLeadershipLevel ||
            reportType === ReportTypeEnum.TwoTwoCompanySelfLeadership
        ) {
            setShowLeadership(true);
            dispatch(getReportLeadershipLevelAsync(surveyId));
        } else {
            setShowLeadership(false);
            setLeadershipLevelId(undefined);
        }

        if (reportType !== ReportTypeEnum.OneThreeCompanyYearOverYear) {
            setCompanyToCompareId(undefined);
        }

        if (reportType !== ReportTypeEnum.OneFiveDivisionYearOverYear) {
            setDivisionToCompareId(undefined);
        }

        if (reportType === ReportTypeEnum.TwoCompanySelf
            || reportType === ReportTypeEnum.TwoCompanySelfDivision
            || reportType === ReportTypeEnum.TwoTwoCompanySelfLeadership
        ) {
            setShowRecommendations(true);
            dispatch(getRecommendationsAsync({
                skip: 0,
                take: 2000,
                query: ""
            }));
        } else {
            setShowRecommendations(false);
        }
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className={`mode-item-container-wrapper mt-4 p-5 rounded shadow bg-white`}>
                    <FormWithHandle onSubmit={() => { }}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">{ResourcesService.translate('Type')}</Form.Label>
                            <Col sm="10">
                                <Form.Select defaultValue={reportType} onChange={onReportSelected}>
                                    <option value={ReportTypeEnum.OneIndustryCompany}>{ResourcesService.translate('1. Company Industry')}</option>
                                    <option value={ReportTypeEnum.OneOneDivisionIndustry}>{ResourcesService.translate('1.1 Company Divsion Industry')}</option>
                                    <option value={ReportTypeEnum.OneTwoCompanyLeadershipLevel}>{ResourcesService.translate('1.2 Company Leadership Level')}</option>
                                    <option value={ReportTypeEnum.OneThreeCompanyYearOverYear}>{ResourcesService.translate('1.3 Company Year Over Year')}</option>
                                    <option value={ReportTypeEnum.OneFourDivisionCompare}>{ResourcesService.translate('1.4 Division Compare')}</option>
                                    <option value={ReportTypeEnum.OneFiveDivisionYearOverYear}>{ResourcesService.translate('1.5 Company Division Year Over Year')}</option>
                                    <option value={ReportTypeEnum.TwoCompanySelf}>{ResourcesService.translate('2.0 Company Self')}</option>
                                    <option value={ReportTypeEnum.TwoCompanySelfDivision}>{ResourcesService.translate('2.1 Company Self Division')}</option>
                                    <option value={ReportTypeEnum.TwoTwoCompanySelfLeadership}>{ResourcesService.translate('2.2 Company Self Leadership Level')}</option>
                                    <option value={ReportTypeEnum.TwoFourDivisionCompare}>{ResourcesService.translate('2.4 Division Compare')}</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">{ResourcesService.translate('Company')}</Form.Label>
                            <Col sm="10">
                                <Form.Select defaultValue={companyId} onChange={e => {
                                    const id = e.currentTarget.value;
                                    setCompanyId(id);
                                    dispatch(getReportCompanyAsync(id));
                                }}>

                                    {companyList.map((d, i) =>
                                        <option value={d.id}>{d.titleBg}</option>

                                    )}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <>
                            {(reportType !== ReportTypeEnum.OneThreeCompanyYearOverYear &&
                                reportType !== ReportTypeEnum.OneFourDivisionCompare &&
                                reportType !== ReportTypeEnum.TwoFourDivisionCompare
                            ) &&
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">{ResourcesService.translate('Survey')}</Form.Label>
                                    <Col sm="10">
                                        <Form.Select defaultValue={surveyId} onChange={e => {
                                            if (reportType === ReportTypeEnum.OneTwoCompanyLeadershipLevel ||
                                                reportType === ReportTypeEnum.TwoTwoCompanySelfLeadership
                                            ) {
                                                setShowLeadership(true);
                                                dispatch(getReportLeadershipLevelAsync(surveyId));
                                            } else {
                                                setShowLeadership(false);
                                                setLeadershipLevelId(undefined);
                                            }
                                            const id = e.currentTarget.value;
                                            setSurveyId(id);
                                        }}>

                                            {editCompany.surveys.map((d, i) =>
                                                <option value={d.id}>{d.titleBg}</option>

                                            )}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            }
                        </>

                        <>
                            {(reportType === ReportTypeEnum.OneThreeCompanyYearOverYear ||
                                reportType === ReportTypeEnum.OneFiveDivisionYearOverYear
                            ) &&
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">{ResourcesService.translate('Company To Compare')}</Form.Label>
                                    <Col sm="10">
                                        <Form.Select defaultValue={companyToCompareId} onChange={e => {
                                            const id = e.currentTarget.value;
                                            setCompanyToCompareId(id);
                                        }}>

                                            {companyList.map((d, i) =>
                                                <option value={d.id}>{d.titleBg}</option>

                                            )}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            }
                        </>

                        <>
                            {(reportType === ReportTypeEnum.OneFiveDivisionYearOverYear) &&
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">{ResourcesService.translate('Division To Compare')}</Form.Label>
                                    <Col sm="10">
                                        <Form.Select defaultValue={divisionToCompareId} onChange={e => {
                                            const id = e.currentTarget.value;
                                            setDivisionToCompareId(id);
                                        }}>

                                            {compareCompany.surveys.map((d, i) =>
                                                <option value={d.id}>{d.titleBg}</option>
                                            )}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            }
                        </>

                        <>
                            {
                                showLeadership &&
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">{ResourcesService.translate('Leadership level')}</Form.Label>
                                    <Col sm="10">
                                        <Form.Select defaultValue={leadershipLevelId} onChange={e => {

                                            const id = e.currentTarget.value;
                                            setLeadershipLevelId(id);
                                        }}>
                                            {leadershipLevelList.map((d, i) =>
                                                <option value={d.id}>{d.titleBg}</option>
                                            )}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            }
                        </>

                        <Form.Label>{ResourcesService.translate('Companies to compare with')}</Form.Label>
                        <Form.Group as={Row} className="mb-3">

                            <>
                                {
                                    compareCompanies.map((s, i) =>
                                        <Form.Group as={Row} className="mb-3" controlId={`surveyId-${s.id}`} key={`surveyId-${s.id}`}>
                                            <Form.Label column sm="2">Компания {i + 1}</Form.Label>
                                            <Col sm="6">
                                                <Form.Control disabled defaultValue={ResourcesService.translateInput(s.titleBg, s.titleEn, lang)} as={'textarea'} />
                                            </Col>
                                            <Col sm='4'>
                                                <DeleteButton lang={lang} status={StatusEnum.Idle} operation={OperationEnum.None} onDelete={() => removeCompareCompany(s)} />
                                            </Col>
                                        </Form.Group>
                                    )
                                }
                            </>
                            <Row>
                                <Col>
                                    <Button variant="outline-secondary" size='sm' onClick={() => setSurveysModalShow(true)}>
                                        <i className='bi bi-plus-lg'></i> {ResourcesService.translate('Add company', lang)}
                                    </Button>
                                </Col>
                            </Row>

                            <Col sm="10">
                                <VerticallyCenteredModal
                                    id='report-add-survey-dialog'
                                    lang={lang}
                                    title={ResourcesService.translate('Select company', lang)}
                                    show={surveysModalShow}
                                    onCancel={() => setSurveysModalShow(false)}
                                    onOk={() => setSurveysModalShow(false)}
                                >
                                    <GenericList
                                        lang={lang}
                                        headContent={
                                            <tr>
                                                {
                                                    tableHeaders.map(h => <th key={h}>{h}</th>)
                                                }
                                            </tr>
                                        }
                                        bodyContent={
                                            compareCompaniesList.data.map((d, i) =>
                                                <tr key={i} onClick={() => addCompareCompany(d)}>
                                                    <td>{d.id}</td>
                                                    <td>{ResourcesService.translateInput(d.titleBg, d.titleEn, lang)}</td>
                                                </tr>
                                            )
                                        }
                                        listStatus={compareCompaniesList.status}
                                        numberOfRows={5}
                                        numberOfColumns={tableHeaders.length}
                                        searchValue={compareCompaniesList.query}
                                        onSearch={(value: string) => dispatch(editCompanyChangeQuery(value))}
                                        onNext={() => dispatch(editCompanyLoadNextPage())}
                                        onPrevious={() => dispatch(editCompanyLoadPreviousPage())}
                                    />
                                </VerticallyCenteredModal>
                            </Col>
                        </Form.Group>
                        <>
                            {showRecommendations &&
                                <>
                                    <Form.Label>{ResourcesService.translate('Препоръки')}</Form.Label>
                                    <Form.Group as={Row} className="mb-3">
                                        <>
                                            {
                                                addedRecommendations.map((s, i) =>
                                                    <Form.Group as={Row} className="mb-3" controlId={`recommendationId-${s.id}`} key={`recommendationId-${s.id}`}>
                                                        <Form.Label column sm="2">Препоръка {i + 1}</Form.Label>
                                                        <Col sm="6">
                                                            <Form.Control disabled defaultValue={ResourcesService.translateInput(s.titleBg, s.titleEn, lang)} as={'textarea'} />
                                                        </Col>
                                                        <Col sm='4'>
                                                            <DeleteButton lang={lang} status={StatusEnum.Idle} operation={OperationEnum.None} onDelete={() => removeRecommendation(s)} />
                                                        </Col>
                                                    </Form.Group>
                                                )
                                            }
                                        </>
                                        <Row>
                                            <Col>
                                                <Button variant="outline-secondary" size='sm' onClick={() => setRecommendationsModalShow(true)}>
                                                    <i className='bi bi-plus-lg'></i> {ResourcesService.translate('Add recommendation', lang)}
                                                </Button>
                                            </Col>
                                        </Row>

                                        <Col sm="10">
                                            <VerticallyCenteredModal
                                                id='report-add-recommendation-dialog'
                                                lang={lang}
                                                title={ResourcesService.translate('Select recommendation', lang)}
                                                show={recommendationsModalShow}
                                                onCancel={() => setRecommendationsModalShow(false)}
                                                onOk={() => setRecommendationsModalShow(false)}
                                            >
                                                <GenericList
                                                    lang={lang}
                                                    headContent={
                                                        <tr>
                                                            {
                                                                tableHeaders.map(h => <th key={h}>{h}</th>)
                                                            }
                                                        </tr>
                                                    }
                                                    bodyContent={
                                                        addedRecommendationsList.data.map((d, i) =>
                                                            <tr key={i} onClick={() => addRecommendation(d)}>
                                                                <td>{d.id}</td>
                                                                <td>{ResourcesService.translateInput(d.titleBg, d.titleEn, lang)}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    listStatus={addedRecommendationsList.status}
                                                    numberOfRows={5}
                                                    numberOfColumns={tableHeaders.length}
                                                    searchValue={addedRecommendationsList.query}
                                                    onSearch={(value: string) => dispatch(editCompanyChangeQuery(value))}
                                                    onNext={() => dispatch(editCompanyLoadNextPage())}
                                                    onPrevious={() => dispatch(editCompanyLoadPreviousPage())}
                                                />
                                            </VerticallyCenteredModal>
                                        </Col>
                                    </Form.Group>
                                </>
                            }
                        </>


                    </FormWithHandle>
                    <Button className={`float-end ms-2`} variant="outline-success" size='sm' onClick={getPDF} disabled={status !== StatusEnum.Idle}>
                        <span> {ResourcesService.translate('Create', lang)}</span>
                    </Button>
                </div>
            </div>
        </div >

    );
}